import React from 'react'
import Img from "gatsby-image"
import "./Solution.css";

function Solution(props) {
    const data = props;

    console.log(data)

    return (
        <div className="flex flex-wrap relative hm-sl-container">
            <div className="block w-full"></div>
            <div className="container m-auto sl-h">
                {data.title && (
                    <h2 className="sl-title pb-8" >
                        {data.title}
                    </h2>
                )}
                <div className="flex flex-wrap pb-8">
                    {data.solutions.map((sol, yndex) => (
                        <div key={`solutionGroup-${yndex}`} className="w-full md:w-1/2 lg:w-1/4 flex p-4">
                            <div className="px-12 py-8  sl-tab w-full" >
                                <div className="w-full flex items-center justify-center">
                                    <Img fixed={sol.solutionGroup.icon.imageFile.childImageSharp.fixed} loading="lazy" alt={sol.solutionGroup.icon.altText} />
                                </div>
                                <h3 className="py-4 sl-h4 text-center">
                                    {sol.solutionGroup.title}
                                </h3>
                                <p className="sl-p">
                                    {sol.solutionGroup.paragraph}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full hidden sm:block" style={{ padding: 0 }}>
                <Img fluid={data.backImg.imageFile.childImageSharp.fluid} className="hm-sl-bg" />
            </div>
            <div className="w-full sm:hidden block" style={{ padding: 0 }} >
                <Img fluid={data.mobBackImg.imageFile.childImageSharp.fluid} className="hm-sl-bg" />
            </div>
        </div>
    )
}

export default Solution;

export const query = graphql`
    fragment HomeSolutionFragment on WPGraphQL_Page_Homepageacf {
        solutionTitle
        solutionBackground{
            sourceUrl
            altText
            imageFile{
                childImageSharp{
                    fluid(maxWidth: 2080, quality:100){
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                }
            }
        }
        solutionBackgroundMobile{
            sourceUrl
            altText
            imageFile{
                childImageSharp{
                    fluid(maxWidth: 517, quality:100){
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                }
            }
        }
        solutions{
            solutionGroup{
                icon{
                    sourceUrl
                    altText
                    imageFile{
                        childImageSharp{
                            fixed(width: 51, quality:100){
                                base64
                                width
                                height
                                src
                                srcSet
                            }
                        }
                    }
                }
                title
                paragraph
            }
        }

        
    }
`