
import React, { useState, useEffect } from 'react'
import axios from "axios"
import DatePicker, { registerLocale } from "react-datepicker"
import { format, parseISO } from 'date-fns'
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import isSameDay from 'date-fns/isSameDay'
import Img from "gatsby-image"
import { el } from 'date-fns/locale'

import { sha256 } from 'js-sha256';

import SortDown from "../../images/chevron-down-solid.svg";
import SortDownWhite from "../../images/chevron-down-solid-white.svg";

// import subDays from 'date-fns/subDays'
import addMonths from 'date-fns/addMonths'
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval'
import "react-datepicker/dist/react-datepicker.css";
import "./AppointmentTemplate.css";

registerLocale("el", el);

function AppointmentTemplate(props) {
    const data = props.cpt
    const faq = props.faq
    const [firstRender, setFirstRender] = useState(true);
    const [firstRenderEr, setFirstRenderEr] = useState(true);


    const [bookComplete, setBookComplete] = useState(false);
    const [bookDateFinal, setBookDateFinal] = useState();
    const [error, setError] = useState("");
    const [activeFaq, setActiveFaq] = useState(0);
    const [exDates, setExDates] = useState([]);

    const [startDate, setStartDate] = useState(
        setHours(setMinutes(new Date(), 0), 9)
    );

    const [booked, setBooked] = useState()

    const [exTimes, setExTimes] = useState();

    useEffect(() => {
        let apps = [];

        axios.get(`https://admin.nextyounutrition.gr/wp-json/wp/v2/appointment?per_page=100`)
            .then(function (response) {
                if (response.data && response.data.length > 0) {
                    apps = apps.concat(response.data)
                }
                axios.get(`https://admin.nextyounutrition.gr/wp-json/wp/v2/appointment/?per_page=100&offset=99`)
                    .then(function (response) {
                        if (response.data && response.data.length > 0) {
                            apps = apps.concat(response.data)
                        }

                        axios.get(`https://admin.nextyounutrition.gr/wp-json/wp/v2/appointment/?per_page=100&offset=199`)
                            .then(function (response) {
                                if (response.data && response.data.length > 0) {
                                    apps = apps.concat(response.data)
                                }
                                setBooked(apps)

                                changeDt(startDate, apps)

                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })

                // setTimeout(() => {
                //     changeDt(startDate , response.data)
                // }, [1500])
                // handle success

            })
            .catch(function (error) {
                // handle error
                console.log(error)
            })
    }, [])

    useEffect(() => {
        if (booked && firstRender) {
            let weekends = eachWeekOfInterval({
                start: new Date(2019, 9, 13),
                end: new Date(2025, 9, 13)
            })


            booked.map((app, i) => {
                // console.log(app)
                if (app.acf.name === "ClosedDate") {
                    weekends.push(new Date(app.acf.datetime))
                }
            })

            // console.log(weekends)
            setExDates(weekends)

            setFirstRender(false)
        }
    }, [booked, firstRender])

    function changeDt(date, bookedList) {
        let excBooked = [];

        // console.log(bookedList)

        if (bookedList) {
            bookedList.map((app, i) => {
                if (isSameDay(new Date(app.acf.datetime), new Date(date))) {
                    excBooked.push(new Date(app.acf.datetime))
                }
            })
        }

        // console.log(excBooked)


        setExTimes(
            excBooked
        )

        // console.log(hours)
        setStartDate(date)

        setTimeout(() => {
            if (firstRenderEr) {
                let elements = document.getElementsByClassName("react-datepicker__time-list-item--disabled");
                let i;
                for (i = 0; i < 18; i++) {
                    if (elements[0]) {
                        elements[0].parentNode.removeChild(elements[0]);
                    }
                }
                setFirstRenderEr(false)
            }
        }, 1000)
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function book() {
        let formData = new FormData();

        // console.log(exTimes)
        // console.log(startDate)
        // console.log(exTimes.includes(startDate))
        let modelName = document.getElementsByName("name")[0].value;
        let modelEmail = document.getElementsByName("email")[0].value;
        let modelService = document.getElementsByName("service")[0].value;

        if (!modelName || !(modelName.length > 3)) {
            setError("Παρακαλούμε συμπληρώστε το πεδίο του ονόματος")
        }

        // console.log(modelEmail)
        if (modelEmail === "") {
            setError("Παρακαλούμε συμπληρώστε το πεδίο του ηλεκτρονικού ταχυδρομείου")
        } else if (!(validateEmail(modelEmail))) {
            setError("Η διεύθυνση ηλεκτρονικού ταχυδρομείου δεν είναι έγκυρη")
        }



        if ((modelName && (modelName.length > 3)) && (validateEmail(modelEmail))) {
            let model = {
                displayDate: format(startDate, 'dd/MM/yyyy H:mm'),
                startDate: startDate,
                name: modelName,
                email: modelEmail,
                service: modelService
            }

            formData.append('model', JSON.stringify(model));
            axios.post(`https://admin.nextyounutrition.gr/wp-json/myplugin/v1/bookAppointment`, formData)
                .then(function (response) {
                    console.log(response)
                    setBookComplete(true)
                    setBookDateFinal(model.displayDate)
                    // handle success
                    window.dataLayer.push({
                        'event': 'book_appointment'
                    });

                    window.fbq('track', 'Schedule');

                    let fbId = localStorage.getItem("fbclid")

                    if (fbId) {
                        fetch('https://graph.facebook.com/v10.0/163187788976281/events/?access_token=EAAB56ZCrMsZAEBABkyFdRFeZBVHEcdkwA2mDQWpoECaZAHjhKby2RL4ZAMXJym3lE1ruxY3w4fuZCZAP0z2cnTw8GNQJlaZAOHpRu6tfvTwLYc3xMzntZBdBtM1ceGg9ZC1dnVJuIp6NplTij1rzEPKWKZBBFETslRlOD23sJg4I7aPcj5WLwOjCHwpCEY7QjFhyxUZD', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                'data': [
                                    {
                                        "event_name": "Schedule",
                                        "event_id": fbId,
                                        "event_source_url": window.location.href.split('?')[0],
                                        "event_time": Math.floor(new Date().getTime() / 1000),
                                        "action_source": "website",
                                        "user_data": {
                                            "em": sha256(modelEmail),
                                            "client_user_agent": navigator.userAgent
                                        },
                                    }
                                ]
                            }),
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                console.log(data)
                            })
                            .catch((err) => console.log(err));
                    }

                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
        } else {
            setError("Παρακαλούμε συμπληρώστε όλα τα πεδία")
        }
    }

    return (
        <div className="w-full" style={{ backgroundImage: `url(${data.appSectionBg.imageFile.childImageSharp.fluid.srcWebp}` }} importance="low">
            <div className="container flex flex-wrap m-auto py-0 lg:py-20 pb-20">
                <div className="w-full lg:w-1/2 relative app-m-w m-auto" >
                    <div>
                        <Img fluid={data.appBg.imageFile.childImageSharp.fluid} className="app-tab-bg" />
                    </div>
                    {!bookComplete && (
                        <div className="p-8 app-form">
                            <h4 className="app-h4">{data.appTitle}</h4>
                            <h5>{data.appSubtitle}</h5>
                            <div>
                                <label for={"name"}>{data.form.name}</label>
                                <input id="name" type="text" name="name" />
                            </div>
                            <div>
                                <label for={"email"}>{data.form.email}</label>
                                <input id="email" type="email" name="email" />
                            </div>
                            <div>
                                <label for={"service"}>{"Υπηρεσίες"}</label>
                                <select id="service" className="app-select" name="service">
                                    {data.form.typeOfService.options.map((option, i) => (
                                        <option key={`service-option-${i}`} value={option.label} >{option.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label style={{ color: "#555" }} for={"app-date"}>{data.form.preferredDate.label}</label>
                                <DatePicker
                                    id="app-date"
                                    selected={startDate}
                                    onChange={date => changeDt(date, booked)}
                                    locale="el"
                                    // excludeDates={[new Date(), subDays(new Date(), 1)]}
                                    minDate={new Date()}
                                    maxDate={addMonths(new Date(), 5)}
                                    minTime={setHours(setMinutes(new Date(), 0), 9)}
                                    maxTime={setHours(setMinutes(new Date(), 0), 20)}
                                    excludeDates={exDates}
                                    showDisabledMonthNavigation
                                    showTimeSelect
                                    excludeTimes={exTimes}
                                    timeIntervals={60}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </div>
                            {error && (
                                <div style={{ maxWidth: "420px", color: "red" }}>
                                    <p>{error}</p>
                                </div>
                            )}
                            <button onClick={book} className="app-bttn my-8">{data.form.submitButton}</button>
                        </div>
                    )}
                    {bookComplete && (
                        <div className="app-response-mssg flex flex-col justify-center" style={{ minHeight: "600px" }}>
                            <h4 className="w-full">Ευχαριστούμε που μας επιλέξατε!</h4>
                            <p className="w-full">Το ραντεβού σας έκλεισε για της {bookDateFinal ? bookDateFinal : ""} </p>
                        </div>
                    )}
                </div>
                {faq && (
                    <div className=" w-full lg:w-1/2 px-4 pt-8 lg:pt-0">
                        <h4 className="faq-h4">{faq.faqTitle}</h4>
                        <div>
                            {faq.faqBlocks.map((f, i) => (
                                <div key={`faq-${i}`}>
                                    <div className={`faq-q flex justify-between items-center cursor-pointer ${activeFaq === i ? "faq-q-active" : ""}`} onClick={() => setActiveFaq(i)}>
                                        <h5>{f.faqGroup.question}</h5>
                                        <div>
                                            {activeFaq !== i
                                                ?
                                                <img src={SortDown} style={{ width: "17px", marginRight: "5px" }} alt="arrow-down" />
                                                :
                                                <img src={SortDownWhite} style={{ width: "17px", marginRight: "5px" }} alt="arrow-down" />
                                            }

                                        </div>
                                    </div>
                                    {activeFaq === i && (
                                        <div className="py-4 flex">
                                            <div className="faq-answer pr-8">
                                                <p>{f.faqGroup.answer}</p>
                                            </div>
                                            <div>
                                                <Img fixed={f.faqGroup.image.imageFile.childImageSharp.fixed} className="faq-img" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AppointmentTemplate;



export const query = graphql`
    fragment AppointmentFragment on WPGraphQL_Template_Appcpt {
            appSectionBg{
                sourceUrl
                altText
                imageFile{
                    childImageSharp{
                        fluid(maxWidth: 1920, quality:80){
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                        }
                    }
                }
            }
            appBg{
                sourceUrl
                altText
                imageFile{
                    childImageSharp{
                        fluid(maxWidth: 600, quality:80){
                            base64
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                        }
                    }
                }
            }
            appTitle
            appSubtitle
            form {
                name
                email
                typeOfService {
                    label
                    options{
                        label
                    }
                }
                preferredDate {
                    label
                    date
                }
                submitButton
            }
        
    }

    fragment FaqFragment on WPGraphQL_Template_Faqcpt {
        faqTitle
        faqBlocks{
            faqGroup{
                question
                answer
                image{
                    sourceUrl
                    altText  
                    imageFile{
                        childImageSharp{
                            fixed(width: 150, quality:100){
                                base64
                                width
                                height
                                src
                                srcSet
                            }
                        }
                    }
                }
            }
        }
    }
`