import React from 'react'
import "./Introduction.css";
import Helmet from "react-helmet"
import {Link} from "gatsby"

function Introduction(props) {

    const data = props;

    console.log(props)

    return (
        <div>
            <Helmet>
                <link rel="preload" as="image" imagesrcset={`${data.mobBackImg.imageFile.childImageSharp.fluid.srcSetWebp}`} />
                {/* <link rel="preload" as="image" href={data.mobBackImg.imageFile.childImageSharp.fluid.src} imagesrcset={`${data.mobBackImg.imageFile.childImageSharp.fluid.srcSet}`} /> */}

            </Helmet>
            <div className="background-home-container flex-wrap relative">
                <div className="home-img-wrapper" style={{ padding: 0 }}>
                    <picture>
                        <source type="image/webp" srcset={ data.backImg.imageFile.childImageSharp.fluid.srcSetWebp } media="(min-width: 601px)" importance="high" />
                        <source type="image/webp" srcset={  data.mobBackImg.imageFile.childImageSharp.fluid.srcSetWebp} media="(max-width: 600px)" importance="high" />
                        <source type="image/jpg" srcset={ data.backImg.imageFile.childImageSharp.fluid.srcSet} media="(min-width: 601px)"/>
                        <source type="image/jpg" srcset={  data.mobBackImg.imageFile.childImageSharp.fluid.srcSet} media="(max-width: 600px)"/>
                        <img
                            importance="high"
                            style={{objectFit: "cover",width: "100%",height: "100%"}}
                            importance="high"
                            alt={data.backImg.altText}
                        />
                    </picture>
                </div>
                <div className="flex justify-center items-center bg-home-wrapper ">
                    <div className="p-4">
                        <div className="px-0 sm:px-12 pt-4 sm:pt-12">
                            <h1 className="home-h1">{data.title}</h1>
                            <p className="home-main-p py-4 hidden sm:block">{data.par}</p>
                        </div>
                        <div className="flex py-4 sm:py-16 home-i-sub">
                            <div className="w-full sm:w-1/2 left-tab">
                                <div>
                                    <picture>
                                        <source type="image/webp" srcSet={data.leftTab.tabIcon.imageFile.childImageSharp.fixed.srcSetWebp} importance="low" />
                                        <img srcSet={data.leftTab.tabIcon.imageFile.childImageSharp.fixed.srcSet} className="m-auto" importance="low" alt={data.leftTab.tabIcon.altText} />
                                    </picture>
                                    <p className="tab-p py-4">{data.leftTab.tabText}</p>
                                    <Link to={data.leftTab.buttonAction} className="tab-bttn">{data.leftTab.buttonText}</Link>
                                </div>
                            </div>
                            <div className="w-1/2 hidden sm:block">
                                <div>
                                    <picture>
                                        <source type="image/webp" srcSet={data.rightTab.tabIcon.imageFile.childImageSharp.fixed.srcSetWebp} importance="low" />
                                        <img srcSet={data.rightTab.tabIcon.imageFile.childImageSharp.fixed.srcSet} className="m-auto" importance="low" alt={data.rightTab.tabIcon.altText} />
                                    </picture>
                                    <p className="tab-p py-4">{data.rightTab.tabText}</p>
                                    <Link to={data.leftTab.buttonAction} className="tab-bttn">{data.rightTab.buttonText}</Link>
                                </div>
                            </div>

                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Introduction;

export const query = graphql`
    fragment HomeIntroFragment on WPGraphQL_Page_Homepageacf {
        introBackground{
            sourceUrl
            altText
            imageFile{
                childImageSharp{
                    fluid(maxWidth: 2080, quality:100){
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                }
            }
        }
        introBackgroundMobile{
            sourceUrl
            altText
            imageFile{
                childImageSharp{
                    fluid(maxWidth: 600, quality:20){
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                }
            }
        }
        introHeader1
        introParagraph
        introLeftTab{
            tabText
            buttonText
            buttonAction
            tabIcon{
                sourceUrl
                altText
                imageFile{
                    childImageSharp{
                        fixed(width: 160, quality:100){
                            width
                            height
                            src
                            srcSet
                        }
                    }
                }
            }
        }
        introRightTab{
            tabText
            buttonText
            buttonAction
            tabIcon{
                sourceUrl
                altText
                imageFile{
                    childImageSharp{
                        fixed(width: 160, quality:100){
                            width
                            height
                            src
                            srcSet
                        }
                    }
                }
            }
        }
    }
`