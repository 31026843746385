import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import Introduction from "../components/Home/Introduction"
import About from "../components/Home/About"
import Services from "../components/Home/Services"
import Solution from "../components/Home/Solution"
import AppointmentTemplate from "../components/Global/AppointmentTemplate"

import Layout from "../components/Layout"

function HomeTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    // useEffect(() => {
    //     const observer = new PerformanceObserver((list) => {
    //         let perfEntries = list.getEntries();
    //         let lastEntry = perfEntries[perfEntries.length - 1];
    //         console.log(lastEntry)
    //         console.log(lastEntry.element )
    //         // Process the latest candidate for largest contentful paint
    //       });
    //     observer.observe({entryTypes: ['largest-contentful-paint']});
    // },[])

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://nextyounutrition.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://nextyounutrition.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    console.log(data)
    return (
        <Layout header={data.menu} footer={data.footer} appointment= {data.template.appCpt} metaData={constructMetaData(data.page, props.pageContext.currentPage)} >
            <section importance="high">
                <Introduction 
                    backImg={data.page.homePageAcf.introBackground} 
                    mobBackImg={data.page.homePageAcf.introBackgroundMobile} 
                    title={data.page.homePageAcf.introHeader1}
                    par={data.page.homePageAcf.introParagraph}
                    leftTab={ data.page.homePageAcf.introLeftTab}
                    rightTab={ data.page.homePageAcf.introRightTab}
                />
            </section>
            <section>
                <About
                    backImg={data.page.homePageAcf.aboutBackground}
                    mobBackImg={data.page.homePageAcf.aboutBackgroundMobile} 
                    editor={data.page.homePageAcf.aboutEditor}
                    leftTab={data.page.homePageAcf.aboutLeftTab}
                    rightTab={data.page.homePageAcf.aboutRightTab}
                />
            </section>
            <section>
                <Services services={ data.services } />
            </section>
            <section>
                <Solution 
                    backImg={data.page.homePageAcf.solutionBackground}
                    mobBackImg={data.page.homePageAcf.solutionBackgroundMobile} 
                    title={data.page.homePageAcf.solutionTitle}
                    solutions={data.page.homePageAcf.solutions}
                />
            </section>

            <section>
                <AppointmentTemplate
                    cpt = { data.template.appCpt}
                    faq = {data.faq.faqCpt}
                />
            </section> 

        </Layout>
    )
}

export default HomeTemplate;

export const pageQuery = graphql`
    query GET_HOME($id: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
                homePageAcf{
                    ...HomeIntroFragment
                    ...HomeAboutFragment
                    ...HomeSolutionFragment
                }
            }
            services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}){
                ...ServicesFragment
            }
            template(id: "cG9zdDoyMDUw"){
                appCpt {
                    ...AppointmentFragment
                }
            }
            faq: template(id: "cG9zdDoyMTA5"){
                faqCpt{
                    ...FaqFragment
                }
            }
            menu(id:"bmF2X21lbnU6MjI="){
                menuItems{
                    edges{
                        node{
                            url
                            label
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footer: template(id:"cG9zdDoyMjE5"){
                id
                title
                footerInformationTemplate{
                    address{
                        addressGoogleLink
                        addressLabel
                    }
                    hoursLabel
                    hoursTitle
                    informationTitle
                    supportEmail
                    telephoneNumber
                }
                footerAcf{
                    googlaMapsImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 750, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    city
                    callToActionText
                }
            }
        }
    }
`
