import React, { useState } from 'react'
import Img from "gatsby-image"
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder";


import "./Services.css";


function Services(props) {
    const data = props;

    return (
        <div className="m-auto text-center py-20">
            {data.title && (
                <h2 className="text-4xl" >
                    {data.title}
                </h2>
            )}
            <div className="container m-auto">
                <div className="flex flex-wrap py-4">
                    {data.services.edges.map((service, yndex) => (
                        <div key={`service-${service.node.title}`} className="w-full sm:w-1/2 md:w-1/3 flex p-4">
                            <Link to={`${service.node.uri.replace("service", "ypiresies")}`} className="p-12 service-tab w-full" >
                                <Img fixed={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.fixed} loading="lazy" alt={service.node.servicesAcf.smallIcon.altText} className="small-icon m-auto" />
                                <h4 className="py-4 services-h4">
                                    {htmlDecode(service.node.title)}
                                </h4>
                                <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} className="service-excerpt-container pb-8" />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Services;

export const query = graphql`
  fragment ServicesFragment on WPGraphQL_RootQueryToServiceConnection {
    edges{
        node{
            uri
            title
            excerpt
            servicesAcf{
                smallIcon{
                    altText
                    sourceUrl
                    imageFile{
                        childImageSharp{
                            fixed(width: 75){
                                base64
                                width
                                height
                                src
                                srcSet
                            }
                        }
                    }
                }
            }
        }
    }
  }
`