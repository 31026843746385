import React, { useState } from 'react'
import Img from "gatsby-image"
import "./About.css";

function About(props) {
    const data = props;

    console.log(data)

    return (
        <div className="flex flex-wrap relative hm-ab-container">
            <div className="hidden xl:block xl:w-1/3">

            </div>
            <div className="w-full xl:w-2/3 flex items-center h-full">
                <div className="hm-tab-wrapper py-8 md:py-0">
                    <div className="hm-ab-editor px-8 md:px-0" dangerouslySetInnerHTML={{ __html: data.editor }}></div>
                    <div className="flex flex-wrap w-full">
                        <div className="w-full md:w-1/2 flex px-8 md:px-0">
                            <div className="w-1/4 md:w-1/3">
                                <div className="ab-icon-wrapper">
                                    <Img fixed={data.leftTab.leftIcon.imageFile.childImageSharp.fixed} className="hm-ab-icon" />
                                </div>
                            </div>
                            <div className="w-3/4 md:w-2/3 ab-tab-content pl-2 md:pl-0">
                                <div>
                                    <h3>{data.leftTab.leftTitle}</h3>
                                </div>
                                <div className="hm-tab-divider"></div>
                                <div>
                                    <p>{data.leftTab.leftParagraph}</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 flex mt-8 md:mt-0 px-8 md:px-0">
                            <div className="w-1/4 md:w-1/3">
                                <div className="ab-icon-wrapper">
                                    <Img fixed={data.rightTab.rightIcon.imageFile.childImageSharp.fixed} className="hm-ab-icon" />
                                </div>
                            </div>
                            <div className="w-3/4 md:w-2/3 ab-tab-content pl-2 md:pl-0">
                                <div>
                                    <h3>{data.rightTab.rightTitle}</h3>
                                </div>
                                <div className="hm-tab-divider"></div>
                                <div>
                                    <p>{data.rightTab.rightParagraph}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full hidden sm:block" style={{ padding: 0 }}>
                <Img fluid={data.backImg.imageFile.childImageSharp.fluid} className="hm-ab-bg" />
            </div>
            <div className="w-full sm:hidden block" style={{ padding: 0 }} >
                <Img fluid={data.mobBackImg.imageFile.childImageSharp.fluid} className="hm-ab-bg" />
            </div>
        </div>
    )
}

export default About;

export const query = graphql`
    fragment HomeAboutFragment on WPGraphQL_Page_Homepageacf {

        aboutBackground{
            sourceUrl
            altText
            imageFile{
                childImageSharp{
                    fluid(maxWidth: 2080, quality:100){
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                }
            }
        }
        aboutBackgroundMobile{
            sourceUrl
            altText
            imageFile{
                childImageSharp{
                    fluid(maxWidth: 517, quality:80){
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                    }
                }
            }
        }
        aboutEditor
        aboutLeftTab{
            leftIcon{
                sourceUrl
                altText
                imageFile{
                    childImageSharp{
                        fixed(width: 45, quality:100){
                            base64
                            width
                            height
                            src
                            srcSet
                        }
                    }
                }
            }
            leftTitle
            leftParagraph
        }
        aboutRightTab{
            rightIcon{
                sourceUrl
                altText
                imageFile{
                    childImageSharp{
                        fixed(width: 45, quality:100){
                            base64
                            width
                            height
                            src
                            srcSet
                        }
                    }
                }
            }
            rightTitle
            rightParagraph
        }
    }
`